import React from 'react';
import logo from '../../assets/img/hulp_logo_beta.svg'

function Navbar() {
    return (
        <div className='uk-container uk-padding'>
            <div data-uk-grid>
                <div className="uk-width-expand">
                    <img className='hulp-logo' src={logo} alt='Hulp Logo' />
                </div>
            </div>
        </div>
    );
}

export default Navbar;
