import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/img/hulp_logo_beta.svg'

function SideNav() {
    const location = useLocation();
    return (
        <div className='uk-padding'>
            <img src={logo} alt='logo' className='dashboard-logo' />
            <ul className="uk-nav uk-nav-default uk-margin-large-top side-nav">
                <li className={location.pathname === '/dashboard' ? 'active' : ''}>
                    <Link to="/dashboard">Home</Link>
                </li>
                <li className={location.pathname === '/campaigns' ? 'active' : ''}>
                    <Link to="/campaigns">Campaigns</Link>
                </li>
                <li className={location.pathname === '/transactions' ? 'active' : ''}>
                    <Link to="/transactions">Transactions</Link>
                </li>
                <li className={location.pathname === '/transfers' ? 'active' : ''}>
                    <Link to="/transfers">Transfers</Link>
                </li>
                <li className={location.pathname === '/settings' ? 'active' : ''}>
                    <Link to="/settings">Settings</Link>
                </li>
            </ul>
        </div>
    );
}

export default SideNav;
