import React from 'react';

function ButtonLoader() {
    return (
        <div id='btnloader' className='uk-inline'>
            <svg height="24" width="24" viewBox="0 0 24 24">
                <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
            </svg>
        </div>
    );
}

export default ButtonLoader;
