import React from 'react';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";
import { API_URL } from '../apiURL'
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import SideNav from './dashboard/SideNav';
import MainNav from './dashboard/MainNav';
import Loader from './common/Loader';
import banks from './dashboard/banks.json'
import ButtonLoader from './common/ButtonLoader';
import Alert from './common/Alert';
import folder_icon from '../assets/img/folder.svg'



function Dialog({ userProfile }) {
    return (
        <div className='dashboard-body uk-margin-bottom'>
            <div className='uk-width-2-5'>
                <h6 className='header-6 uk-margin-large-bottom'>Hello  {userProfile.firstName}, 👋🏼</h6>
                <img src={folder_icon} alt='folder icon' />
                <p className='p-bold'>Your hulp account is currently been
                    verified for approval.</p>
                <p>You will be contacted via email on the status  of your
                    account verification</p>
            </div>
        </div>
    );
}


class Dashboard extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            banks: banks.data,
            bvn: '',
            bankName: '',
            bankCode: '0',
            accountName: '',
            accountNumber: '',
            address: '',
            idProof: [],
            idProof_name: '',
            errorMessage: '',
            alertError: false,
            notLoggedIn: false,
            userProfile: [],
            isProcessing: false,
            isCompleted: false,
            showProfile: false
        }
    }

    componentDidMount() {
        this.checkCookie()
        this.getUserProfile()
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    getUserProfile = () => {
        const { cookies } = this.props;

        axios.get(API_URL + '/user/profile', {
            headers: {
                'Authorization': "Bearer " + cookies.get('token')
            }
        })
            .then((response) => {
                if (response.status === 200 && response.data.data !== "" && response.data.status === "success") {
                    this.setState({ userProfile: response.data.data })
                    this.setState({ isLoading: false })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e)
                }
            });
    }

    checkVerificationStatus = () => {

    }

    toggleProfile = () => {
        this.setState((prevState) => ({
            showProfile: !prevState.showProfile
        }));
    }


    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    fileinputChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check file size (1 MB = 1024 KB)
            if (file.size > 1024 * 1024) {
                // File size exceeds 1MB
                this.setState({ alertError: true, errorMessage: "File size exceeds 1MB. Please choose a smaller file.", idProof: '' })
                window.scrollTo({ top: 0, behavior: 'smooth', });
                e.target.value = ''; // Reset the file input
                return;
            }

            // Check file type
            const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
            if (!allowedFileTypes.includes(file.type)) {
                this.setState({ alertError: true, errorMessage: "Invalid file type. Please choose a JPG, PNG, or PDF file.", idProof: '' })
                window.scrollTo({ top: 0, behavior: 'smooth', });
                e.target.value = ''; // Reset the file input
                return;
            }


            // Set state if the file is valid
            this.setState({ idProof_name: file.name });
            this.setState({ [e.target.name]: file });
        }
    }



    handleSelectChange = (e) => {
        const selectedBankCode = e.target.value;
        const selectedBank = this.state.banks.find(bank => bank.code === selectedBankCode);

        if (selectedBank) {
            this.setState({
                bankName: selectedBank.name,
                bankCode: selectedBank.code,
            });
        }
    }

    submitCompliance = (e) => {
        e.preventDefault();
        const { cookies } = this.props;


        if (this.state.bankCode === '0' && this.state.bankName === "") {

            this.setState({ alertError: true, errorMessage: "Please select a bank" })
            window.scrollTo({ top: 0, behavior: 'smooth', });
        }
        else {
            this.setState({ alertError: false })

            let formData = new FormData();
            formData.append('accountName', this.state.userProfile.firstName + ' ' + this.state.userProfile.lastName);
            formData.append('accountNumber', this.state.accountNumber);
            formData.append('bankName', this.state.bankName);
            formData.append('bankCode', this.state.bankCode);
            formData.append('address', this.state.address);
            formData.append('idProof', this.state.idProof);

            axios.post(API_URL + '/compliance/store-information', formData, {
                headers: {
                    'Authorization': "Bearer " + cookies.get('token')
                }
            })
                .then((response) => {
                    console.log(response)
                    // if (response.status === 200 && response.data.data !== "" && response.data.status === "success") {
                    //     this.setState({ userProfile: response.data.data })
                    //     this.setState({ isLoading: false })
                    // }
                })
                .catch((e) => {
                    if (e.response) {
                        console.log(e)
                    }
                });
            alert("Compliance submit")
            console.log('compliance')
        }

    }



    render() {
        // Sort the banks array by name alphabetically
        const sortedBanks = [...this.state.banks].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        return (
            <div>

                <div>
                    {this.state.notLoggedIn &&
                        <Navigate to="/" replace={true} />
                    }
                    <Loader isLoading={this.state.isLoading} />

                    <div className='uk-text-center verification-pending-bar'>
                        <p className='meta-text-semibold uk-margin-remove white'>You're currently not verified, please submit your compliance documents to be able to create campaigns</p>
                    </div>

                    <div className='dashboard uk-grid' data-uk-grid>
                        <div className='uk-width-auto@m nav-vertical'>
                            <SideNav />
                        </div>
                        <div className='uk-width-expand@m uk-padding uk-padding-remove-vertical'>
                            <div className='dashboard-nav-wrapper'>
                                <div className='dashboard-nav-spacer'></div>
                                <MainNav userProfile={this.state.userProfile} title="Compliance" emoji="📂" />
                                <div className='dashboard-nav-spacer'></div>
                            </div>
                            {this.state.isCompleted
                                ?
                                <Dialog userProfile={this.state.userProfile} />
                                :
                                <div className='dashboard-body uk-margin-bottom'>
                                    <div className='uk-width-4-5'>
                                        <div data-uk-grid>
                                            <div className='uk-width-expand@m'>
                                                <h6 className='header-6'>Hello {this.state.userProfile.firstName},<br />
                                                    let's setup your Hulp account 🚀</h6>
                                            </div>
                                            <div className='uk-width-auto@m'>
                                                <p className='meta-text-medium account-type-pill'>Individual Account</p>
                                            </div>
                                        </div>
                                        <div className='uk-flex uk-flex-middle uk-margin-top' data-uk-grid>
                                            <div className='uk-width-auto@m'>
                                                <p className='meta-text'>1 / 2 steps complete</p>
                                            </div>
                                            <div className='uk-width-expand@m'>
                                                <progress className="uk-progress" value="50" max="100"></progress>
                                            </div>
                                        </div>
                                        <Alert message={this.state.errorMessage} error={this.state.alertError} />
                                        <p className='body uk-margin-large-top'>Kindly fill the form below to verify your account</p>
                                        <div data-uk-grid className='uk-flex uk-flex-middle'>
                                            <div className='uk-width-auto@m'>
                                                <p className='body account-type-pill'>Profile Information</p>
                                            </div>
                                            <div className='uk-width-expand@m uk-flex uk-flex-right'>
                                                <a  href={() => false} className='meta-text-medium' onClick={this.toggleProfile}>
                                                    Click to {this.state.showProfile ? 'collapse' : 'expand'}
                                                </a>
                                            </div>
                                        </div>
                                        {this.state.showProfile ? (
                                            <form className='uk-margin-small-top' data-uk-grid>
                                                <div className="uk-width-1-1@m"></div>
                                                <div className="uk-margin-small uk-width-1-2@m">
                                                    <label className="uk-hulp-label" htmlFor="firstName">First name</label>
                                                    <input className="uk-input uk-hulp-input" id="firstName" name='firstName' type="text" value={this.state.userProfile.firstName} readOnly={true} required disabled />
                                                </div>
                                                <div className="uk-margin-small uk-width-1-2@m">
                                                    <label className="uk-hulp-label" htmlFor="lastName">Last name</label>
                                                    <input className="uk-input uk-hulp-input" id="lastName" name='lastName' type="text" value={this.state.userProfile.lastName} readOnly={true} required disabled />
                                                </div>
                                                <div className="uk-margin-small uk-width-1-2@m">
                                                    <label className="uk-hulp-label" htmlFor="email">Email address</label>
                                                    <input className="uk-input uk-hulp-input" id="email" name='email' type="email" value={this.state.userProfile.email} readOnly={true} required disabled />
                                                </div>
                                                <div className="uk-margin-small uk-width-1-2@m">
                                                    <label className="uk-hulp-label" htmlFor="phone">Phone number</label>
                                                    <input className="uk-input uk-hulp-input" id="phone" name='phone' type="tel" maxLength="11" value={this.state.userProfile.phone} readOnly={true} required disabled />
                                                </div>
                                            </form>
                                        ) : null}

                                        <div data-uk-grid>
                                            <div className='uk-width-auto@m'>
                                                <p className='body account-type-pill'>Banking Information</p>
                                            </div>
                                        </div>
                                        <form data-uk-grid id='individual_compliance_form' method='POST' className='uk-margin-small-top' onSubmit={this.submitCompliance} >
                                            <div className="uk-width-1-1@m"></div>
                                            {/* <div className="uk-margin-small uk-width-1-2@m">
                                        <label className="uk-hulp-label" htmlFor="bvn">Bank Verification Number (BVN)</label>
                                        <input className="uk-input uk-hulp-input" id="bvn" name='bvn' type="text" required />
                                    </div> */}
                                            <div className="uk-margin-small uk-width-1-2@m">
                                                <label className="uk-hulp-label" htmlFor="bankName">Bank Name</label>
                                                <select className='uk-input uk-hulp-input' id="bankName" onChange={this.handleSelectChange}>
                                                    <option disabled selected defaultValue="0">Select a Bank</option>
                                                    {sortedBanks.map((bank) => (
                                                        <option key={bank.code} value={bank.code} >
                                                            {bank.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="uk-margin-small uk-width-1-2@m">
                                                <label className="uk-hulp-label" htmlFor="accountNumber">Account number</label>
                                                <input className="uk-input uk-hulp-input" id="accountNumber" name='accountNumber' type="text" onChange={this.inputChange} required />
                                            </div>
                                            <div className="uk-margin-small uk-width-1-2@m">
                                                <label className="uk-hulp-label" htmlFor="accountName">Name on Account</label>
                                                <input className="uk-input uk-hulp-input" id="accountName" name='accountName' type="text" value={this.state.userProfile.firstName + ' ' + this.state.userProfile.lastName} onChange={this.inputChange} disabled required />
                                            </div>
                                            <div className='uk-width-1-1@m' data-uk-grid>
                                                <div className='uk-width-auto@m'>
                                                    <p className='body account-type-pill'>Additional Information</p>
                                                </div>
                                            </div>

                                            <div className="uk-margin-small uk-width-1-2@m">
                                                <label className="uk-hulp-label" htmlFor="address">Address</label>
                                                <textarea className="uk-textarea uk-hulp-textarea" id="address" name='address' rows="3" placeholder="" aria-label="Textarea" onChange={this.inputChange} required></textarea>

                                            </div>
                                            <div className="uk-margin-small uk-width-1-2@m">
                                                <label className="uk-hulp-label" htmlFor="idProof">Proof of Identity (Gov't issued ID)</label>
                                                <div className="uk-hulp-upload uk-flex uk-flex-center">
                                                    {/* <span className="uk-text-middle">Upload proof of identification <br /></span> */}
                                                    <div data-uk-form-custom className=''>
                                                        <input className='' type="file" id="idProof" name='idProof' multiple onChange={this.fileinputChange} required />
                                                        <button className="uk-upload-btn" type="button" tabIndex="-1">Upload Proof of Identification</button>

                                                        {this.state.idProof && <h6 className='meta-text uk-margin-remove uk-margin-top'>{this.state.idProof_name}</h6>}
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="uk-width-1-2@m">
                                                <button form='individual_compliance_form' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                                                    {this.state.isProcessing ? <ButtonLoader /> : "Verify Account"}
                                                </button>
                                            </div>

                                        </form>


                                    </div>
                                </div>
                            }
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default withCookies(Dashboard)
