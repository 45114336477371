import React from 'react';
import { API_URL } from '../apiURL'
import axios from 'axios';
import withParams from './common/withParams'
import Navbar from './common/Navbar';
import Alert from './common/Alert';
import verify_icon from '../assets/img/verify.svg'
import error_icon from '../assets/img/error.svg'
import Loader from './common/Loader'

function Dialog() {
    return (
        <div >
            <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
                <img src={verify_icon} alt='verify icon' />
                <h5 className='header-5'>Account verified </h5>
                <p>Your Account Has been verified. You will be redirected to login to your dashboard shortly</p>
                <p className='p-bold'>Not Redirecting?</p>
                <p>if it fails to redirect, click the button below</p>
                <a href='/' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>Log in</a>
            </div>
        </div>
    )
}


class Verify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            token: '',
            verifyError: false,
            isCompleted: false

        }
    }

    componentDidMount() {
        let { token } = this.props.params;
        this.setState({ token: token }, function () { this.verifyAccount() })
    }

    verifyAccount = () => {
        const payload = {
            token: this.state.token,
        }
        axios.post(API_URL + '/verify-email', payload)
            .then((response) => {
                if (response.status === 200 && response.data.status === "success") {
                    this.setState({ isLoading: false, isCompleted: true })
                }
            })
            .catch((e) => {
                if (e.response.status === 400 && e.response.data.status === "error") {
                    this.setState({ verifyError: true, errorMessage: e.response.data.data, isLoading: false, isCompleted: false })
                    window.scrollTo({ top: 0, behavior: 'smooth', });
                }
            });
    }

    render() {
        return (
            <div>
                <Navbar />
                <Loader isLoading={this.state.isLoading} />
                <div className='uk-container uk-padding'>
                    {this.state.isCompleted
                        ?
                        <Dialog />
                        :
                        <div className='uk-container uk-padding'>
                            <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
                                <img src={error_icon} alt='error icon' />
                                <p>An error has occurred!</p>
                                <br />
                                <Alert message={this.state.errorMessage} error={this.state.verifyError} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default withParams(Verify);
