import React from 'react';

function MainNav({ userProfile, title, emoji }) {
    const initials = (String(userProfile.firstName).charAt(0) + String(userProfile.lastName).charAt(0)).toUpperCase()
    return (
        <div className='dashboard-nav'>
            <div className='uk-grid' data-uk-grid>
                <div className='uk-width-1-2@m uk-flex uk-flex-middle'>
                    <h6 className='header-6 uk-margin-remove'><span className='header-emoji' role="img" aria-label="folder emoji">{emoji}</span>{title}</h6>
                </div>
                <div className='uk-width-1-2@m uk-flex uk-flex-right'>
                    <div className='name-block uk-flex uk-flex-middle'>
                        <p className='meta-text-semibold verification-pending-pill uk-margin-remove-bottom uk-margin-right'>Verification Pending</p>
                        <p className='p-bold profile-text uk-margin-remove'>{initials}</p>
                        <div className='uk-margin-small-left'>
                            <p className='p-bold uk-margin-remove'>{userProfile.firstName} {userProfile.lastName} </p>
                            <p className='meta-text uk-margin-remove'>{userProfile.email} </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainNav;