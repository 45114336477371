import React from 'react';

function Alert({ message,error }) {
    return (
        <div className="uk-margin" style={(!error) ? { display: 'none' } : {}}>
            <div className="uk-hulp-alert" data-uk-alert>
                {/* <a href='/#' className="uk-alert-close" data-uk-close> </a> */}
                <p>{message}</p>
            </div>
        </div>
    );
}

export default Alert;
