import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { API_URL } from '../../apiURL'
import axios from 'axios';
import Navbar from '../common/Navbar';
import Loader from '../common/Loader'
import ButtonLoader from '../common/ButtonLoader'
import Alert from '../common/Alert';



class Login extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      type: 'password',
      email: '',
      password: '',
      isProcessing: false,
      loginError: false,
      errorMessage: '',
      loggedin: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: false })
  }

  passwordToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text'
    })
  }

  inputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitLogin = (e) => {
    e.preventDefault();
    this.setState({ isProcessing: true })
    const payload = {
      email: this.state.email,
      password: this.state.password
    }
    const { cookies } = this.props;

    axios.post(API_URL + '/login', payload)
      .then((response) => {
        if (response.status === 200 && response.data.token !== "" && response.data.status === "success") {
          cookies.set('token', response.data.token, { path: '/' });
          this.setState({ isProcessing: false, loginError: false, loggedin: true })
        }
      })
      .catch((e) => {
        if (e.response) {
          this.setState({ loginError: true, errorMessage: e.response.data.data, isProcessing: false })
          window.scrollTo({ top: 0, behavior: 'smooth', });
        }
      });
  }

  render() {
    return (
      <div>
        <Loader isLoading={this.state.isLoading} />
        {this.state.loggedin && <Navigate to="/dashboard" replace={true} />
        }
        <div>
          <Navbar />
          <div className='uk-container uk-padding'>
            <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
              <h5 className='header-5'>Log in to your account</h5>
              <form className='hulp-form' method='POST' onSubmit={this.submitLogin}>
                <Alert message={this.state.errorMessage} error={this.state.loginError} />
                <div className="uk-margin">
                  <label className="uk-hulp-label" htmlFor="email">Email</label>
                  <input className="uk-input uk-hulp-input" id="email" name='email' type="email" onChange={this.inputChange} required />
                </div>
                <div className="uk-margin password-input">
                  <label className="uk-hulp-label " htmlFor="password">Password</label>
                  <input className="uk-input uk-hulp-input" id="password" name='password' type={this.state.type} onChange={this.inputChange} required />
                  <span onClick={this.passwordToggle} className="meta-text-medium show-btn">{this.state.type === 'text' ? 'Hide' : 'Show'}</span>
                </div>
                <div className="uk-margin">
                  <button className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                    {this.state.isProcessing ? <ButtonLoader /> : "Log in"}
                  </button>
                </div>
                <div className="uk-margin">
                  <p className='body-medium'>Forgot your password? <a className='robin-egg-blue reset-link' href='/forgot-password'>Reset it here</a></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(Login)