import React from 'react';
import { API_URL } from '../apiURL'
import axios from 'axios';
import Navbar from './common/Navbar';
import Loader from './common/Loader'
import ButtonLoader from './common/ButtonLoader'
import Alert from './common/Alert'
import send_icon from '../assets/img/send.svg'
import soon from '../assets/img/soon.svg'



class Dialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            isCompleted: false,
        }
    }
    // !! repurposed isCompleted to be a trigger for the alert instead of registerError
    resendEmail = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })

        const payload = {
            email: this.props.email,
        }
        axios.post(API_URL + '/resend-verification-email', payload)
            .then((response) => {
                if (response.status === 201 && response.data.status === "success") {
                    this.setState({ isProcessing: false, isCompleted: true, alertMessage: "An email has been sent to: " + this.props.email })
                }
            })
            .catch((e) => {
                if (e.response.status === 400 && e.response.data.status === "error") {
                    this.setState({ isCompleted: true, alertMessage: e.response.data.data, isProcessing: false })
                }
            });
    }
    render() {
        return (
            <div>
                <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
                    <Alert message={this.state.alertMessage} error={this.state.isCompleted} />

                    <img src={send_icon} alt='send icon' />

                    <h5 className='header-5'>You've got mail</h5>
                    <p>We've sent an email to your email, <span className='p-bold'>{this.props.email} </span>with a link to confirm your account which expires in ⏰ 10 minutes</p>
                    <p></p>
                    <p className='p-bold'>Didn't get our email?</p>
                    <p>The email might be in your spam folder.</p>
                    <a href='#0' onClick={this.resendEmail} className="uk-button uk-hulp-button uk-width-1-1" type='submit'> {this.state.isProcessing ? <ButtonLoader /> : "Resend email"}</a>

                </div>
            </div>
        )
    }
}


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            account_type: '',
            isProcessing: false,
            passwordtype: 'password',
            passwordconfirmtype: 'password',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmpassword: '',
            phone: '',
            orgName: '',
            businessName: '',
            registerError: false,
            errorMessage: '',
            isCompleted: false, 
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }

    passwordToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordtype: this.state.passwordtype === 'text' ? 'password' : 'text'
        })
    }

    passwordConfirmToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordconfirmtype: this.state.passwordconfirmtype === 'text' ? 'password' : 'text'
        })
    }


    selectIndividualAccount = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            account_type: 'individual'
        })
    }
    selectNonprofitAccount = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            account_type: 'nonprofit'
        })
    }

    EditAccountType = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            account_type: ''
        })
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitRegistration = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })
        const payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone,
            type: this.state.account_type,
            orgName: this.state.orgName,
            businessName: this.state.businessName,
        }


        if (this.state.password === this.state.confirmpassword) {
            this.setState({ registerError: false, errorMessage: '' })

            axios.post(API_URL + '/register', payload)
                .then((response) => {
                    if (response.status === 201 && response.data.status === "success") {
                        this.setState({ isProcessing: false, isCompleted: true })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 400 && e.response.data.status === "error") {
                        this.setState({ registerError: true, errorMessage: e.response.data.data, isProcessing: false })
                        window.scrollTo({ top: 0, behavior: 'smooth', });
                    }
                });

        }
        else {
            this.setState({ registerError: true, errorMessage: 'Passwords do not match', isProcessing: false })
            window.scrollTo({ top: 0, behavior: 'smooth', });

        }


    }



    render() {
        return (
            <div>
                <Loader isLoading={this.state.isLoading} />
                <Navbar />
                <div className='uk-container uk-padding'>
                    {this.state.account_type === '' &&
                        <div className="uk-width-1-2@m uk-margin-auto uk-margin-large-top">
                            <h5 className='header-3 uk-text-center '>Get started with your hulp account</h5>
                            <h5 className='header-5 header-5-mobile uk-text-center'>choose your account type</h5>
                            <div className="uk-margin">
                                <a onClick={this.selectIndividualAccount} href='/#' className="account-type-link">
                                    <div className='account-type-btn'>
                                        <div className='uk-grid-small' data-uk-grid>
                                            <div className="uk-width-auto">
                                                <svg className='icon-svg' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M25 26.25V23.75C25 22.4239 24.4732 21.1521 23.5355 20.2145C22.5979 19.2768 21.3261 18.75 20 18.75H10C8.67392 18.75 7.40215 19.2768 6.46447 20.2145C5.52678 21.1521 5 22.4239 5 23.75V26.25" stroke="#004453" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M15 13.75C17.7614 13.75 20 11.5114 20 8.75C20 5.98858 17.7614 3.75 15 3.75C12.2386 3.75 10 5.98858 10 8.75C10 11.5114 12.2386 13.75 15 13.75Z" stroke="#004453" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="uk-width-expand">
                                                <h6 className='header-6 uk-margin-remove'>Individual</h6>
                                                <p className='body uk-margin-remove'>I'm trying this for myself and personal endeavours</p>
                                            </div>
                                            <div className="uk-width-auto uk-flex">
                                                <svg className='chevron-svg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18L15 12L9 6" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="uk-margin">
                                {/* <a onClick={this.selectNonprofitAccount} href='/#' className="account-type-link"> */}
                                <a href='/register' className="account-type-link">
                                    <div className='account-type-btn' style={{position:'relative'}}>
                                        <div className='uk-grid-small' data-uk-grid>
                                            <div className="uk-width-auto">
                                                <svg className='icon-svg' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M26.0497 5.76234C25.4112 5.1236 24.6532 4.6169 23.8189 4.2712C22.9845 3.92549 22.0903 3.74756 21.1872 3.74756C20.2841 3.74756 19.3898 3.92549 18.5555 4.2712C17.7211 4.6169 16.9631 5.1236 16.3247 5.76234L14.9997 7.08734L13.6747 5.76234C12.385 4.47273 10.636 3.74823 8.81216 3.74823C6.98837 3.74823 5.23928 4.47273 3.94966 5.76234C2.66005 7.05196 1.93555 8.80105 1.93555 10.6248C1.93555 12.4486 2.66005 14.1977 3.94966 15.4873L5.27466 16.8123L14.9997 26.5373L24.7247 16.8123L26.0497 15.4873C26.6884 14.8489 27.1951 14.0909 27.5408 13.2565C27.8865 12.4222 28.0644 11.5279 28.0644 10.6248C28.0644 9.72173 27.8865 8.82747 27.5408 7.99315C27.1951 7.15882 26.6884 6.40079 26.0497 5.76234Z" stroke="#004453" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </div>
                                            <div className="uk-width-expand">
                                                <h6 className='header-6 uk-margin-remove'>Nonprofit <img src={soon} alt='coming soon' /></h6>
                                                <p className='body uk-margin-remove'>For charitable and religious organisations, NGO's,
                                                    other development-oriented & social enterprises.</p>
                                            </div>
                                            <div className="uk-width-auto uk-flex">
                                                <svg className='chevron-svg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18L15 12L9 6" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    }
                    {this.state.account_type === 'individual' &&
                        <div>
                            {this.state.isCompleted
                                ?
                                <Dialog email={this.state.email} />
                                :
                                <div className="uk-width-1-2@m uk-margin-auto">
                                    <Alert message={this.state.errorMessage} error={this.state.registerError} />

                                    <form id='individual_form' className="uk-grid-small" data-uk-grid method='POST' onSubmit={this.submitRegistration}>
                                        <div className="uk-margin-small uk-width-1-1">
                                            <div className='account-selected-btn'>
                                                <div className='uk-grid-small' data-uk-grid>
                                                    <div className="uk-width-auto">
                                                        <svg className='icon-svg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#17D4BE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#17D4BE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <h6 className='header-6 uk-margin-remove'>Individual</h6>
                                                    </div>
                                                    <div className="uk-width-auto">
                                                        <a onClick={this.EditAccountType} href='/#' className='meta-text-medium'>Edit</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="firstName">First name</label>
                                            <input className="uk-input uk-hulp-input" id="firstName" name='firstName' type="text" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="lastName">Last name</label>
                                            <input className="uk-input uk-hulp-input" id="lastName" name='lastName' type="text" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="email">Email address</label>
                                            <input className="uk-input uk-hulp-input" id="email" name='email' type="email" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="phone">Phone number</label>
                                            <input className="uk-input uk-hulp-input" id="phone" name='phone' type="tel" maxLength="11" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m password-input">
                                            <label className="uk-hulp-label" htmlFor="password">Password</label>
                                            <input className="uk-input uk-hulp-input" id="password" name='password' type={this.state.passwordtype} onChange={this.inputChange} required />
                                            <span onClick={this.passwordToggle} className="meta-text-medium show-btn">{this.state.passwordtype === 'text' ? 'Hide' : 'Show'}</span>
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m password-input">
                                            <label className="uk-hulp-label" htmlFor="confirmpassword">Confirm password</label>
                                            <input className="uk-input uk-hulp-input" id="confirmpassword" name='confirmpassword' type={this.state.passwordconfirmtype} onChange={this.inputChange} required />
                                            <span onClick={this.passwordConfirmToggle} className="meta-text-medium show-btn">{this.state.passwordconfirmtype === 'text' ? 'Hide' : 'Show'}</span>
                                        </div>

                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <label><input className="uk-checkbox uk-hulp-checkbox" type="checkbox" onChange={this.inputChange} required /> I acknowledge that I have read, understood and agree to Hulp's <a href='/' >Privacy Policy</a>, and <a href='/'>Terms and conditions.</a></label>
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <button form='individual_form' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                                                {this.state.isProcessing ? <ButtonLoader /> : "Create account"}
                                            </button>
                                        </div>
                                        <div className="uk-margin-small">
                                            <p className='body-medium'>Already have an account?  <a className='robin-egg-blue reset-link' href='/'>Log in</a></p>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>

                    }
                    {this.state.account_type === 'nonprofit' &&
                        <div>
                            {this.state.isCompleted
                                ?
                                <Dialog email={this.state.email} />
                                :
                                <div className="uk-width-1-2@m uk-margin-auto">
                                    <Alert message={this.state.errorMessage} error={this.state.registerError} />
                                    <form id='nonprofit_form' className="uk-grid-small" data-uk-grid method='POST' onSubmit={this.submitRegistration}>
                                        <div className="uk-margin-small uk-width-1-1">
                                            <div className='account-selected-btn'>
                                                <div className='uk-grid-small' data-uk-grid>
                                                    <div className="uk-width-auto">
                                                        <svg className='icon-svg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20.8401 4.60987C20.3294 4.09888 19.7229 3.69352 19.0555 3.41696C18.388 3.14039 17.6726 2.99805 16.9501 2.99805C16.2276 2.99805 15.5122 3.14039 14.8448 3.41696C14.1773 3.69352 13.5709 4.09888 13.0601 4.60987L12.0001 5.66987L10.9401 4.60987C9.90843 3.57818 8.50915 2.99858 7.05012 2.99858C5.59109 2.99858 4.19181 3.57818 3.16012 4.60987C2.12843 5.64157 1.54883 7.04084 1.54883 8.49987C1.54883 9.95891 2.12843 11.3582 3.16012 12.3899L4.22012 13.4499L12.0001 21.2299L19.7801 13.4499L20.8401 12.3899C21.3511 11.8791 21.7565 11.2727 22.033 10.6052C22.3096 9.93777 22.4519 9.22236 22.4519 8.49987C22.4519 7.77739 22.3096 7.06198 22.033 6.39452C21.7565 5.72706 21.3511 5.12063 20.8401 4.60987Z" stroke="#17D4BE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>

                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <h6 className='header-6 uk-margin-remove'>Nonprofit</h6>
                                                    </div>
                                                    <div className="uk-width-auto">
                                                        <a onClick={this.EditAccountType} href='/#' className='meta-text-medium'>Edit</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="firstName">First name</label>
                                            <input className="uk-input uk-hulp-input" id="firstName" name='firstName' type="text" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="lastName">Last name</label>
                                            <input className="uk-input uk-hulp-input" id="lastName" name='lastName' type="text" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <label className="uk-hulp-label" htmlFor="orgName">Orgarnisation name</label>
                                            <input className="uk-input uk-hulp-input" id="orgName" name='orgName' type="text" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="email">Orgarnisation email </label>
                                            <input className="uk-input uk-hulp-input" id="email" name='email' type="email" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m">
                                            <label className="uk-hulp-label" htmlFor="phone">Orgarnisation phone number</label>
                                            <input className="uk-input uk-hulp-input" id="phone" name='phone' type="tel" maxLength="11" onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m password-input">
                                            <label className="uk-hulp-label" htmlFor="password">Password</label>
                                            <input className="uk-input uk-hulp-input" id="password" name='password' type={this.state.passwordtype} onChange={this.inputChange} required />
                                            <span onClick={this.passwordToggle} className="meta-text-medium show-btn">{this.state.passwordtype === 'text' ? 'Hide' : 'Show'}</span>
                                        </div>
                                        <div className="uk-margin-small uk-width-1-2@m password-input">
                                            <label className="uk-hulp-label" htmlFor="confirmpassword">Confirm password</label>
                                            <input className="uk-input uk-hulp-input" id="confirmpassword" name='confirmpassword' type={this.state.passwordconfirmtype} onChange={this.inputChange} required />
                                            <span onClick={this.passwordConfirmToggle} className="meta-text-medium show-btn">{this.state.passwordconfirmtype === 'text' ? 'Hide' : 'Show'}</span>
                                        </div>

                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <label><input className="uk-checkbox uk-hulp-checkbox" type="checkbox" onChange={this.inputChange} required /> I acknowledge that I have read, understood and agree to Hulp's <a href='/' >Privacy Policy</a>, and <a href='/'>Terms and conditions.</a></label>
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <button form='nonprofit_form' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                                                {this.state.isProcessing ? <ButtonLoader /> : "Create account"}
                                            </button>
                                        </div>
                                        <div className="uk-margin-small">
                                            <p className='body-medium'>Already have an account?  <a className='robin-egg-blue reset-link' href='/'>Log in</a></p>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div >
        );
    }
}

export default Register;
