import React from 'react';

function Loader({ isLoading }) {
    return (
        <div id='loader' style={(!isLoading) ? { display: 'none' } : {}}>
            <svg height="32" width="32" viewBox="0 0 24 24">
                <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
            </svg>
        </div>
    );
}

export default Loader;
