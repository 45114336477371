import React from 'react';
import { API_URL } from '../../apiURL'
import axios from 'axios';
import send_icon from '../../assets/img/send.svg'
import Navbar from '../common/Navbar';
import Alert from '../common/Alert';
import Loader from '../common/Loader'
import ButtonLoader from '../common/ButtonLoader'


function Dialog() {
    return (
        <div>
            <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
                <img src={send_icon} alt='send icon' />
                <h5 className='header-5'>You've got mail</h5>
                <p>We've sent an email to you with a link to
                    reset your password</p>
                <p className='p-bold'>Didn't get our email?</p>
                <p>The email might be in your spam folder.</p>
                <a href='/' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>Back to Log in</a>
            </div>
        </div>
    )
}

class FogotPassword extends React.Component {
    state = {
        isLoading: true,
        isProcessing: false,
        isCompleted: false,
        email: "",
        resetError: false
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    forgotPassword = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })

        const payload = {
            email: this.state.email,
        }

        axios.post(API_URL + '/forgot-password', payload)
            .then((response) => {
                if (response.status === 201 && response.data.status === "success") {
                    this.setState({ isProcessing: false, isCompleted: true })
                }
            })
            .catch((e) => {
                if (e.response.status === 400 && e.response.data.status === "success") {
                    this.setState({ resetError: true, errorMessage: e.response.data.data, isProcessing: false })
                    window.scrollTo({ top: 0, behavior: 'smooth', });
                }
            });
    }
    
    render() {
        return (
            <div>
                <Loader isLoading={this.state.isLoading} />

                <Navbar />
                <div className='uk-container uk-padding'>

                    {this.state.isCompleted
                        ?
                        <Dialog />
                        :
                        <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
                            <h5 className='header-5'>Forgot your password?</h5>
                            <p>Enter your email address and we'll send you a link to reset
                                your password.</p>
                            <Alert message={this.state.errorMessage} error={this.state.resetError} />
                            <form className='hulp-form' method='POST' onSubmit={this.forgotPassword}>
                                <div className="uk-margin">
                                    <label className="uk-hulp-label" htmlFor="email">Email</label>
                                    <input className="uk-input uk-hulp-input" id="email" type="email" name='email' placeholder="" onChange={this.inputChange} required />
                                </div>

                                <div className="uk-margin">
                                    <button className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                                        {this.state.isProcessing ? <ButtonLoader /> : "Reset password"}
                                    </button>
                                </div>
                                <div className="uk-margin">
                                    <p className='body-medium'>Return to <a className='robin-egg-blue reset-link' href='/'>Login</a></p>
                                </div>
                            </form>
                        </div>

                    }
                </div >
            </div >
        );
    }
}

export default FogotPassword;
