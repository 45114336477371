import React from 'react';
import { API_URL } from '../../apiURL'
import axios from 'axios';
import Alert from '../common/Alert';
import Navbar from '../common/Navbar';
import lock_icon from '../../assets/img/lock.svg'
import Loader from '../common/Loader'
import ButtonLoader from '../common/ButtonLoader'
import withParams from '../common/withParams'



function Dialog() {
  return (
    <div>
      <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
        <img src={lock_icon} alt='send icon' />
        <h5 className='header-5'>Password changed</h5>
        <p>Your password has been changed successfully You will be redirected to login to your dashboard shortly</p>
        <p className='p-bold'>Not Redirecting?</p>
        <p>if it fails to redirect, click the button below</p>
        <a href='/' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>Log in</a>
      </div>
    </div>
  )
}

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isProcessing: false,
      isCompleted: false,
      type: 'password',
      updateError: false,
      password: '',
      confirm_password: '',
      token: ''
    }
  }

  componentDidMount() {
    let { token } = this.props.params;
    this.setState({ isLoading: false, token: token })
  }

  passwordToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text'
    })
  }

  passwordConfirmToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
        passwordconfirmtype: this.state.passwordconfirmtype === 'text' ? 'password' : 'text'
    })
}

  inputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  updatePassword = (e) => {
    e.preventDefault();
    this.setState({ isProcessing: true })

    const payload = {
      password: this.state.password,
      confirm_password: this.state.confirm_password,
      token: this.state.token
    }
    if (this.state.password === this.state.confirm_password) {
      this.setState({ updateError: false, errorMessage: '' })
      axios.post(API_URL + '/reset-password', payload)
        .then((response) => {
          if (response.status === 200 && response.data.status === "success") {
            this.setState({ isProcessing: false, isCompleted: true })
          }
        })
        .catch((e) => {
          console.log(e.response)
          if (e.response.status === 400 && e.response.data.status === "error") {
            this.setState({ updateError: true, errorMessage: e.response.data.data, isProcessing: false })
            window.scrollTo({ top: 0, behavior: 'smooth', });
          }
        });
    }
    else {
      this.setState({ updateError: true, errorMessage: 'Passwords do not match', isProcessing: false })
      window.scrollTo({ top: 0, behavior: 'smooth', });
    }

  }

  render() {
    return (
      <div>
        <Navbar />
        <Loader isLoading={this.state.isLoading} />

        <div className='uk-container uk-padding'>
          {this.state.isCompleted
            ?
            <Dialog />
            :
            <div className="uk-width-1-3@m uk-margin-auto uk-margin-large-top">
              <h5 className='header-5'>Create new password</h5>
              <Alert message={this.state.errorMessage} error={this.state.updateError} />
              <form className='hulp-form' method='POST' onSubmit={this.updatePassword}>
                <div className="uk-margin password-input">
                  <label className="uk-hulp-label " htmlFor="password">Password</label>
                  <input className="uk-input uk-hulp-input" id="password" name='password' type={this.state.type} placeholder="" onChange={this.inputChange} required />
                  <span onClick={this.passwordToggle} className="meta-text-medium show-btn">{this.state.type === 'text' ? 'Hide' : 'Show'}</span>
                </div>
                <div className="uk-margin password-input">
                  <label className="uk-hulp-label " htmlFor="confirm_password">Confirm Password</label>
                  <input className="uk-input uk-hulp-input" id="confirm_password" name='confirm_password' type={this.state.type} placeholder="" onChange={this.inputChange} required />
                  <span onClick={this.passwordConfirmToggle} className="meta-text-medium show-btn">{this.state.passwordconfirmtype === 'text' ? 'Hide' : 'Show'}</span>
                </div>

                <div className="uk-margin">
                  <button className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                    {this.state.isProcessing ? <ButtonLoader /> : "Update password"}
                  </button>
                </div>
                <div className="uk-margin">
                  <p className='body-medium'>Return to <a className='robin-egg-blue reset-link' href='/'>Login</a></p>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withParams(UpdatePassword);
